<template>
  <div>
    <b-row>
      <b-col
        xl="9"
        md="9"
        sm="12"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>

          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <b-avatar
                  :src="competition.image"
                  :text="competition.name"
                  :variant="`light-primary`"
                  size="104px"
                  rounded
                />
                <div class="d-flex flex-column ml-1 text-truncate">
                  <div class="mb-1">
                    <h4 class="mb-0">
                      {{ competition.name }}
                    </h4>
                  </div>
                  <div class="d-flex flex-wrap">
                    <b-button
                      :to="{ name: 'competitions-edit', params: { id: competition.uuid } }"
                      variant="primary"
                    >
                      Modifier
                    </b-button>
                    <b-button
                      v-b-modal="`modal-delete-competition-${competition.uuid}`"
                      :disabled="deleteLoading"
                      variant="outline-danger"
                      class="ml-0 ml-sm-1"
                    >
                      Supprimer
                    </b-button>
                    <b-modal
                      :id="`modal-delete-competition-${competition.uuid}`"
                      :ref="`modal-delete-competition-${competition.uuid}`"
                      ok-variant="danger"
                      ok-title="Supprimer"
                      modal-class="modal-danger"
                      centered
                      title="Suppression de la compétition"
                    >
                      <b-card-text>
                        Souhaitez vous vraiment supprimer cette competition ?<br>
                        Ces éléments seront supprimés :<br>
                        -Questions<br>
                        -Réponses<br>
                        -Matchs<br>
                        -Équipes sportives<br>
                        -Pronostics (sur les matchs et les questions)<br>
                        -Les points des joueurs seront remis à 0 (ainsi que le classement)
                        <p class="text-danger font-weight-bold">
                          (Cette action est réversible)
                        </p>
                      </b-card-text>
                      <template #modal-footer>
                        <b-button
                          variant="secondary"
                          @click="hideModal()"
                        >
                          annuler
                        </b-button>
                        <b-button
                          variant="danger"
                          :disabled="deleteLoading"
                          @click="deleteCompetition()"
                        >
                          <b-spinner
                            v-show="deleteLoading"
                            small
                          />
                          supprimer
                        </b-button>
                      </template>
                    </b-modal>
                  </div>
                </div>
              </div>

              <!-- Competition Stats -->
              <b-row class="mt-2">
                <b-col>
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-primary"
                      rounded
                    >
                      <feather-icon
                        icon="LayoutIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ competition.platforms_count }} <small>plateformes</small>
                      </h5>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="d-flex align-items-center">
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ moment(competition.created_at).format("LLL") }}
                      </h5>
                      <small>Date de création</small>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <div class="d-flex align-items-center mr-2">
                    <b-avatar
                      variant="light-success"
                      rounded
                    >
                      <feather-icon
                        icon="UsersIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ competition.platforms_users_count }}
                      </h5>
                      <small>Joueurs</small>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="d-flex align-items-center">
                    <b-avatar
                      variant="light-warning"
                      rounded
                    >
                      <feather-icon
                        icon="DollarSignIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ competition.platforms_slot_sum * 2 }}€ ht
                      </h5>
                      <small>Estimation</small>
                    </div>
                  </div>
                </b-col>
                <b-col>
                  <div class="d-flex align-items-center">
                    <b-avatar
                      variant="light-danger"
                      rounded
                    >
                      <feather-icon
                        icon="ZapIcon"
                        size="18"
                      />
                    </b-avatar>
                    <div class="ml-1">
                      <h5 class="mb-0">
                        {{ competition.competition_forecasts_count }}
                      </h5>
                      <small>Pronostics</small>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="6"
              class="text-truncate"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Nom</span>
                  </th>
                  <td class="pb-50">
                    {{ competition.name }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="DribbbleIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Sport</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ competition.sport }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Date de début</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ moment(competition.start_at).format("LL") }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Date de fin</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ moment(competition.end_at).format("LL") }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="ActivityIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Status</span>
                  </th>
                  <td class="pb-50">
                    <b-badge :variant="resolveStatusVariant(competition.status)">
                      {{ competition.status }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="ShoppingCartIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Commandable ?</span>
                  </th>
                  <td class="pb-50">
                    <b-badge :variant="resolveBooleanVariant(competition.is_orderable)">
                      {{ competition.is_orderable }}
                    </b-badge>
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon
                      icon="EditIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Competition gérée par le client</span>
                  </th>
                  <td class="pb-50">
                    <b-badge :variant="resolveBooleanVariant(competition.is_editable_by_client)">
                      {{ competition.is_editable_by_client }}
                    </b-badge>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
        <b-button
          :disabled="sendEndMailLoading"
          variant="outline-danger"
          class="ml-0 ml-sm-1"
          @click="sendEndMail"
        >
          Envoyer le mail de fin de compétition
        </b-button>
      </b-col>
      <b-col
        xl="3"
        md="3"
        sm="12"
      >
        <b-card no-body>
          <b-table
            :items="competition.platforms"
            :fields="tableColumns"
            :sticky-header="true"
            :no-border-collapse="true"
          >
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.favicon"
                    :text="avatarText(data.item.name)"
                    :to="{ name: 'platforms-view', params: { id: data.item.uuid } }"
                  />
                </template>
                <b-link
                  :to="{ name: 'platforms-view', params: { id: data.item.uuid } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
              </b-media>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <h3 class="text-center mb-3 mt-1">
      Questions
      <b-button
        variant="primary"
        :to="{ name: 'questions-create', params: {idCompetition: competition.uuid} }"
        size="sm"
      >
        <span class="align-middle">Ajouter</span>
        <feather-icon
          icon="PlusSquareIcon"
          class="ml-50"
        />
      </b-button>
    </h3>
    <b-row cols="12">
      <b-col>
        <CompetitionViewQuestionsList />
      </b-col>
    </b-row>
    <h3 class="text-center mb-3 mt-1">
      Vision joueurs
    </h3>
    <b-row class="justify-content-center">
      <CompetitionsViewTimeline />
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BSpinner,
  BBadge,
  BTable,
  BMedia, BModal, BCardText,
} from 'bootstrap-vue'
import CompetitionViewQuestionsList from '@/views/competitions/competitions-view/CompetitionViewQuestionsList.vue'
import CompetitionsViewTimeline from '@/views/competitions/competitions-view/CompetitionsViewTimeline.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import axiosIns from '@/libs/axios'

export default {
  name: 'CompetitionView',
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
    BButton,
    BSpinner,
    BBadge,
    BTable,
    BMedia,
    BModal,
    BCardText,
    CompetitionViewQuestionsList,
    CompetitionsViewTimeline,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveBooleanVariant = bool => (bool ? 'light-success' : 'light-danger')
    return {
      avatarText,
      resolveStatusVariant,
      resolveBooleanVariant,
    }
  },
  data() {
    return {
      deleteLoading: false,
      sendEndMailLoading: false,
      loading: true,
      timeline: [],
      tableColumns: [
        {
          key: 'name',
          label: 'Plateformes',
        },
      ],
      loadingTimeline: true,
      timelineSearchQuery: '',
      timelinePerPageOptions: [5, 25, 50, 100],
      timelinePerPage: 25,
      timelineCurrentPage: 1,
      totalTimeline: 0,
      timelineDataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      competition: 'competitions/competition',
    }),
  },
  mounted() {
    this.fetchCompetition()
    this.fetchTimeline()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async fetchCompetition() {
      try {
        this.loading = true
        await this.$store.dispatch('competitions/fetchCompetition', this.$route.params.id)
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.competition.name,
          breadcrumb: [
            {
              text: 'Competitions',
              active: false,
              to: {
                name: 'competitions',
              },
            },
            {
              text: this.competition.name,
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async fetchTimeline(clearPagination = false) {
      try {
        this.loadingTimeline = true
        const timeline = await axiosIns(`/competitions/${this.$route.params.id}/timeline`, {
          params: {
            status: this.statusFilter,
            term: this.timelineSearchQuery,
            page: clearPagination ? 1 : this.currentPage,
            perPage: this.perPage,
            sortBy: this.sortBy,
            sortDirection: this.isSortDirDesc ? 'desc' : 'asc',
          },
        })
        this.timeline = timeline.data.data
        this.loadingTimeline = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteCompetition() {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`competitions/${this.competition.uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La competition a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.hideModal()
        await this.$router.push({
          path: '/competitions',
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    hideModal() {
      this.$refs[`modal-delete-competition-${this.competition.uuid}`].hide()
    },
    changePage(e) {
      this.timelineCurrentPage = e
      this.fetchTimeline()
    },
    sendEndMail() {
      if (!window.confirm('Vraiment envoyer le mail de fin de compétition ?')) return
      this.sendEndMailLoading = true
      axiosIns.post(`competitions/${this.competition.uuid}/sendThanksMail`)
        .then(() => {
          this.$toast(
            {
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Enregistré !',
                icon: 'CheckCircleIcon',
                variant: 'success',
                text: 'Le mail de fin de compétition a été envoyé',
              },
            },
            { timeout: 5000 },
          )
          this.sendEndMailLoading = false
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erreur',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: `Une erreur s'est produite. erreur: ${err}`,
              },
            },
            { timeout: 5000 },
          )
          this.sendEndMailLoading = false
        })
    },
  },
}
</script>

<style scoped>

</style>
